import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'

import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import PAGE_CONTENTS from '../../content/pages/careers.json'

import TICKETS from '../img/careers/tickets.svg'
import ROCKETSHIP from '../img/careers/rocketship.svg'
import PLANET from '../img/careers/planet.svg'

const Careers = props => {
  const [dataAlreadyFetched, setDataAlreadyFetched] = React.useState(false)

  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const grabJobsData = () => {
    var rootId = 'BambooHR-ATS'
    var el = document.getElementById('BambooHR')

    if (!el) {
      console.error(
        'BambooHR-ATS: Hmm, Looks like we are missing something, Double check the code snippet.'
      )
      return
    }

    var departmentId = el.getAttribute('data-departmentId')
    var domain = el.getAttribute('data-domain')
    var version = el.getAttribute('data-version') || '1.0.0'

    if (!domain) {
      console.error(
        'BambooHR-ATS: We are unable to retrieve the domain, Double check the code snippet.'
      )
      return
    }

    var root = document.createElement('div')
    root.id = rootId
    el.appendChild(root)

    var footer = document.createElement('div')
    footer.style =
      'font-family: "Lato", sans-serif; font-size: 12px; color: #999; text-align: right; margin: 9px 9px 0px 0px;'
    var poweredBy = document.createTextNode('Powered by')
    var logo = document.createElement('img')
    logo.src = 'https://resources.bamboohr.com/images/footer-logo.png'
    logo.alt = 'BambooHR - HR software'
    logo.style = 'display:inline;'
    var link = document.createElement('a')
    link.href = 'http://www.bamboohr.com'
    link.target = '_blank'
    link.rel = 'external'

    link.appendChild(logo)
    footer.appendChild(poweredBy)
    footer.appendChild(link)

    el.appendChild(footer)

    var embedUrl =
      'https://' +
      domain +
      '/jobs/embed2.php?version=' +
      encodeURIComponent(version) +
      ''

    if (departmentId) {
      embedUrl += '&departmentId=' + encodeURIComponent(departmentId)
    }

    var xmlhttp = new XMLHttpRequest()

    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        var content = xmlhttp.responseText
        if (root) {
          root.innerHTML = content
          root.querySelectorAll('a').forEach(el => {
            el.setAttribute('target', '_blank')
          })
        }
      }
    }
    xmlhttp.open('GET', embedUrl, true)
    xmlhttp.send()
  }

  const slider = React.useRef(null)

  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    initialSlide: 0,
    speed: 500,
	autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: dots => (
      <div>
        <div className="cp-slider-control">
          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickPrev()
            }}
            className="cp-arrow-left"
          >
            <BsChevronLeft />
          </a>

          <ul style={{ margin: '0px' }}> {dots} </ul>

          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              slider.current.slickNext()
            }}
            className="cp-arrow-right"
          >
            <BsChevronRight />
          </a>
        </div>
      </div>
    ),
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined' && dataAlreadyFetched === false) {
      grabJobsData()
      setDataAlreadyFetched(true)
    }
  })

  return (
    <Layout
      location={props.location}
      title={siteTitle}
      HeaderExtension={
        <div className="header_extension no_bg press careers aligncenter">
          <div className="bg">
            <div className="container">
              <Fade bottom duration={1000} delay={125} distance={'25%'}>
                <h2>Blast off to your dream job</h2>
              </Fade>

              <Fade bottom duration={1000} delay={250} distance={'25%'}>
                <p>Knocking is hiring</p>
              </Fade>
            </div>

            <div className="page-content pages-careers container">
              <div className="columns section-cards">
                <div className="column">
                  <div className="card">
                    <img src={TICKETS} />

                    <h3>All aboard the rocketship</h3>

                    <p>
                      Our solution is unprecedented and so is our company
                      culture. We’ve built something huge here, and the journey
                      is just beginning.
                    </p>
                  </div>
                </div>

                <div className="column">
                  <div className="card">
                    <img src={ROCKETSHIP} />

                    <h3>Help us launch a revolution</h3>

                    <p>
                      We’re a rapidly growing, relentlessly driven team of
                      go-getters who are turning commerce-driven content on its
                      head. Sounds like fun, right?
                    </p>
                  </div>
                </div>

                <div className="column">
                  <div className="card">
                    <img src={PLANET} />

                    <h3>Join us in the stratosphere</h3>
                    <p>
                      We’re always looking for bright thinkers, makers & doers
                      to propel us upward, so if you’ve got the talent and
                      passion, we’ve got a seat for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <SEO
        title="Careers"
        keywords={['Career opportunities', 'Available roles']}
        description="Looking for exciting career opportunities? Explore our available roles and join us on the rocket ship."
      />

      <div className="page-content pages-press pages-careers">
        <div className="section-press">
          <div className="container">
            <div className="columns post-single ui-grid">
              <div className="column is-10">
                <h4>Available seats on the rocketship</h4>

                <div
                  id="BambooHR"
                  data-domain="knockinginc.bamboohr.com"
                  data-version="1.0.0"
                  data-departmentid=""
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content pages-sf pages-careers">
        <div className="container">
          <div className="columns post-single ui-grid">
            <div className="column is-10">
              <Slider className="cp-slider" {...settings} ref={slider}>
                {(PAGE_CONTENTS.testimonials || []).map((slide, i) => (
                  <div key={`slide-${i}`}>
                    <p className="cp-text">{slide.text}</p>

                    <div className="cp-prs">
                      {slide.image && <img src={slide.image} />}

                      <p className="cp-name">{slide.name}</p>
                      <p className="cp-company">{slide.company}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <PreFooter
        title="Watch your career take off"
        linkText="Questions? Email us"
        href="mailto:hello@knocking.com"
      />
    </Layout>
  )
}

export default Careers

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
